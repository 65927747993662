@import 'config';

// Base mobile footer
.greystar-shared-footer {
  align-items: flex-start;
  background: $color-dark;
  color: $color-light;
  display: flex;
  flex-direction: column;
  font-size: $font-size;
  font-style: normal;
  gap: $gap; // scss-lint:disable PropertySpelling
  line-height: $line-height;
  padding: $padding;
  width: 100%;

  * {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: $gap; // scss-lint:disable PropertySpelling
  }

  .links-container {
    flex-direction: column;
    margin: 0;
    padding: 0;

    @media(min-with: 800px) {
      flex-direction: row;
    }
  }

  .link-label {
    align-items: flex-start;
    color: $color-light;
    display: inline-flex;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $color-white;
    }

    &:focus {
      border: 1px solid $color-light;
    }
  }

  .button-greystar-footer {
    align-items: flex-start;
    display: flex;
  }

  .divider {
    background: $color-mid;
    height: 1px;
    width: 100%;
  }

  .secondary-container {
    .links-container {
      flex-direction: row;
    }
  }
}

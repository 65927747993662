$color-dark: #000;
$color-light: #959595;
$color-mid: #2c2c2c;
$color-white: #fff;

$gap: 24px;
$padding: 24px;

$font-size: 16px;
$font-style: normal;
$font-weight: 500;
$line-height: 16px;
